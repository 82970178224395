import Flex from 'components/layout/Flex';
import Image from 'next/image';
import React from 'react';
import styled, { css } from 'styled-components';
import { FlexDiv } from 'styles/commonStyle';
import starRating4 from '../../../public/img/main/starRating4.svg';
import starRating5 from '../../../public/img/main/starRating5.svg';
import Box from 'components/layout/Box';
import { useMediaQuery } from 'react-responsive';

const FeedbackItemBox = styled(FlexDiv)`
    background: ${({ theme }) => theme.colors.white};
    width: 773px;
    height: 350px;
    border-radius: 47.647px;
    box-shadow:
        0px 0px 0px 0px rgba(0, 0, 0, 0.1),
        0px 21px 46px 0px rgba(0, 0, 0, 0.1),
        0px 83px 83px 0px rgba(0, 0, 0, 0.09),
        0px 187px 112px 0px rgba(0, 0, 0, 0.05),
        0px 333px 133px 0px rgba(0, 0, 0, 0.01),
        0px 520px 146px 0px rgba(0, 0, 0, 0);
    margin-right: 100px;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        width: 335px;
        height: auto;
        border-radius: 10px;
        margin-right: 0;
        padding: 40px 20px;
    }
`;

const Tag = styled(FlexDiv)<{ tagBackgroundColor: string }>`
    padding: 0 10px;
    height: 31px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 19px;
    font-weight: 700;
    width: fit-content;
    ${({ tagBackgroundColor }) =>
        tagBackgroundColor &&
        css`
            background-color: ${tagBackgroundColor};
        `}

    @media screen and (max-width: 1024px) {
        padding: 0 20px;
        height: 25px;
        border-radius: 5px;
        font-size: ${({ theme }) => theme.fontSizes.small};
    }
`;

const SecondBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;
    width: 100%;
    @media screen and (max-width: 1024px) {
        margin-top: 30px;
    }
`;

const InfoText = styled.span`
    margin-left: 14.12px;
    color: #707070;
    font-size: 19px;
    b {
        color: ${({ theme }) => theme.colors.black};
        font-weight: 700;
    }
    @media screen and (max-width: 1024px) {
        margin-left: 10px;
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const ContentText = styled.p`
    font-size: ${({ theme }) => theme.fontSizes.mediumLarge};
    line-height: 130%;
    margin-top: 22.47px;
    b {
        font-weight: 700;
    }
    @media screen and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSizes.small};
        margin-top: 40px;
    }
`;

const FeedbackItem = ({
    tagText,
    imageSrc,
    tagBackgroundColor,
    name,
    job,
    content,
    perfectScore,
}) => {
    const onMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <FeedbackItemBox>
            <Box
                width={!onMobile && '667.878px'}
                height={!onMobile && '208.352px'}
            >
                <Flex justifyContent={'space-between'}>
                    <Tag tagBackgroundColor={tagBackgroundColor}>{tagText}</Tag>
                    {onMobile && (
                        <Image
                            src={perfectScore ? starRating5 : starRating4}
                            alt="starRating"
                            width={98}
                        />
                    )}
                </Flex>
                <SecondBlock>
                    <Flex alignItems={'center'}>
                        <Image
                            src={imageSrc}
                            alt="profileImage"
                            width={!onMobile ? 69.8 : 50}
                        />
                        <InfoText>
                            <b>{name}</b> | {job}
                        </InfoText>
                    </Flex>
                    {!onMobile && (
                        <Image
                            src={perfectScore ? starRating5 : starRating4}
                            alt="starRating"
                        />
                    )}
                </SecondBlock>
                <ContentText
                    dangerouslySetInnerHTML={{ __html: content }}
                ></ContentText>
            </Box>
        </FeedbackItemBox>
    );
};

export default FeedbackItem;
