import Box from 'components/layout/Box';
import Flex from 'components/layout/Flex';
import Image from 'next/image';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { FlexDiv } from 'styles/commonStyle';
import { SubTitle } from 'styles/mainStyle';
import mainIconMark from '../../../../public/img/main/mainIconMark.svg';
import mainIconClock from '../../../../public/img/main/mainIconClock.svg';
import mainIconMentoring from '../../../../public/img/main/mainIconMentoring.svg';

const CardBlock = styled(Flex)`
    margin-top: -231px;
    justify-content: center;
    gap: 21px;
    height: 357px;
    z-index: 1;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        margin-top: -52px;
        height: auto;
        align-items: center;
        gap: 30px;
    }
`;

const Card = styled(Flex)`
    width: 346px;
    height: 100%;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding-top: 40px;
    @media screen and (max-width: 1024px) {
        width: 238px;
        gap: 20px;
        padding: 30px 0;
    }
`;

const Line = styled.div`
    width: 74px;
    height: 2px;
    background: ${({ theme }) => theme.colors.white};
`;

const CardTitle = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: normal;
    }
`;

const CardIconWrapper = styled(FlexDiv)`
    width: 265px;
    height: 157px;
    @media screen and (max-width: 1024px) {
        width: 122.997px;
        height: 99.022px;
        margin-top: 5px;
    }
`;

const IconImage = styled(Image)`
    @media screen and (max-width: 1024px) {
        width: 123px;
    }
`;

const Background = styled(Flex)`
    background: #323351;
    height: 1248px;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1024px) {
        padding: 0 20px 70px;
        height: auto;
    }
`;

const ArrowIcon = styled.div`
    margin-top: 70px;
    > span {
        color: ${({ theme }) => theme.colors.white};
        font-size: 54px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 51px;
    }
`;

const SectionTitle = styled(SubTitle)`
    color: ${({ theme }) => theme.colors.white};
    margin-top: 150px;
    @media screen and (max-width: 1024px) {
        margin-top: 100px;
    }
`;

const Chart = styled(Flex)`
    width: 1080px;
    height: 487px;
    border-radius: 20px;
    background: linear-gradient(
        180deg,
        #eff1f5 88.71%,
        #eff1f5 88.71%,
        rgba(239, 241, 245, 0) 120.12%
    );
    margin-top: 100px;
    flex-direction: column;
    gap: 30px;
    padding: 58px 0 55px;
    @media screen and (max-width: 1024px) {
        margin-top: 50.79px;
        width: 335px;
        height: 382px;
        background: ${({ theme }) => theme.colors.white};
        flex-direction: row;
        gap: 20px;
        padding: 33px 49px 0;
    }
`;

const ChartBlock = styled(Flex)`
    align-items: center;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        height: 100%;
        justify-content: end;
        gap: 10px;
    }
`;

const ChartBar = styled(Box)`
    height: 71px;
    border-radius: 0px 10px 10px 0px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 700;
    margin-right: 30px;
    padding: 21px 25px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const MobileChartBar = styled(Box)`
    width: 44px;
    border-radius: 10px 10px 0px 0px;
    filter: drop-shadow(3px 0px 4px rgba(0, 0, 0, 0.25));
`;

const EtcChartBar = styled(ChartBar)`
    color: #767676;
    font-size: ${({ theme }) => theme.fontSizes.mediumLarge};
`;

const PercentText = styled(Box)`
    font-size: 30px;
    font-weight: 800;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        font-weight: 700;
    }
`;

const EtcPercentText = styled(PercentText)`
    font-size: ${({ theme }) => theme.fontSizes.large};
`;

const MobileChartTextBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const MobileChartColor = styled(Box)`
    width: 14px;
    height: 14px;
    border-radius: 2px;
`;

const MobileChartText = styled.div`
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
`;

const MainSection2 = () => {
    const onMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <Background>
            <CardBlock>
                <Card backgroundColor={'blue'}>
                    <Line />
                    <CardTitle>
                        IT 직군
                        <br />
                        주니어 인가요?
                    </CardTitle>
                    <CardIconWrapper>
                        <IconImage src={mainIconMark} alt="checkmark" />
                    </CardIconWrapper>
                </Card>
                <Card backgroundColor={'#3961EF'}>
                    <Line />
                    <CardTitle>
                        빠르게
                        <br />
                        취업하고 싶은가요?
                    </CardTitle>
                    <CardIconWrapper>
                        <IconImage src={mainIconClock} alt="mainIconClock" />
                    </CardIconWrapper>
                </Card>
                <Card backgroundColor={'purple'}>
                    <Line />
                    <CardTitle>
                        취업 관련
                        <br />
                        멘토링이 필요하신가요?
                    </CardTitle>
                    <CardIconWrapper>
                        <IconImage
                            src={mainIconMentoring}
                            alt="mainIconMentoring"
                        />
                    </CardIconWrapper>
                </Card>
            </CardBlock>
            <ArrowIcon>
                <span className="material-symbols-outlined">expand_more</span>
            </ArrowIcon>
            <SectionTitle>
                구직 시 {onMobile && <br />}어떤 도움이 필요한가요?
            </SectionTitle>
            <Chart>
                {!onMobile && (
                    <>
                        <ChartBlock>
                            <ChartBar backgroundColor={'blue'} width={'906px'}>
                                채용 매니저를 통해 나에게 맞는 기업을 추천 받고
                                싶어요
                            </ChartBar>
                            <PercentText color={'blue'}>49%</PercentText>
                        </ChartBlock>
                        <ChartBlock>
                            <ChartBar
                                backgroundColor={'#3961EF'}
                                width={'665px'}
                            >
                                취업에 필요한 중요한 정보를 한 눈에 볼 수
                                있었으면 좋겠어요
                            </ChartBar>
                            <PercentText color={'#3961EF'}>26%</PercentText>
                        </ChartBlock>
                        <ChartBlock>
                            <ChartBar
                                backgroundColor={'#8520F5'}
                                width={'493px'}
                            >
                                내 프로젝트 경험과 유사한 기업을 알고 싶어요
                            </ChartBar>
                            <PercentText color={'#8520F5'}>17%</PercentText>
                        </ChartBlock>
                        <ChartBlock>
                            <EtcChartBar
                                backgroundColor={'#E6E9F5'}
                                width={'219px'}
                            >
                                기타
                            </EtcChartBar>
                            <EtcPercentText color={'#767676'}>
                                8%
                            </EtcPercentText>
                        </ChartBlock>
                    </>
                )}
                {onMobile && (
                    <>
                        <ChartBlock>
                            <PercentText color={'blue'}>49%</PercentText>
                            <MobileChartBar
                                height={'318px'}
                                backgroundColor={'blue'}
                            />
                        </ChartBlock>
                        <ChartBlock>
                            <PercentText color={'#3961EF'}>26%</PercentText>
                            <MobileChartBar
                                height={'244px'}
                                backgroundColor={'#3961EF'}
                            />
                        </ChartBlock>
                        <ChartBlock>
                            <PercentText color={'#8520F5'}>17%</PercentText>
                            <MobileChartBar
                                height={'152px'}
                                backgroundColor={'#8520F5'}
                            />
                        </ChartBlock>
                        <ChartBlock>
                            <PercentText color={'#767676'}>8%</PercentText>
                            <MobileChartBar
                                height={'42px'}
                                backgroundColor={'#E6E9F5'}
                            />
                        </ChartBlock>
                    </>
                )}
            </Chart>
            {!onMobile && (
                <Box
                    marginTop={'20px'}
                    color={'rgba(252, 252, 252, 0.30)'}
                    width={'1080px'}
                    textAllign={'right'}
                >
                    *스타팅 자체 설문조사 결과
                </Box>
            )}
            {onMobile && (
                <Flex
                    marginTop={'40px'}
                    gap={'15px'}
                    width={'335px'}
                    flexDirection={'column'}
                >
                    <MobileChartTextBox>
                        <MobileChartColor backgroundColor={'blue'} />
                        <MobileChartText>
                            채용 매니저를 통해 기업을 추천 받고 싶어요
                        </MobileChartText>
                    </MobileChartTextBox>
                    <MobileChartTextBox>
                        <MobileChartColor backgroundColor={'#3961EF'} />
                        <MobileChartText>
                            취업에 필요한 중요 정보를 한 눈에 보고 싶어요
                        </MobileChartText>
                    </MobileChartTextBox>
                    <MobileChartTextBox>
                        <MobileChartColor backgroundColor={'#8520F5'} />
                        <MobileChartText>
                            내 프로젝트 경험과 유사한 기업을 알고 싶어요
                        </MobileChartText>
                    </MobileChartTextBox>
                    <MobileChartTextBox>
                        <MobileChartColor backgroundColor={'#E6E9F5'} />
                        <MobileChartText>기타</MobileChartText>
                    </MobileChartTextBox>
                </Flex>
            )}
        </Background>
    );
};

export default MainSection2;
