import Box from 'components/layout/Box';
import Flex from 'components/layout/Flex';
import React from 'react';
import styled, { css } from 'styled-components';
import mainMatchingReport from '../../../../public/img/main/mainMatchingReport.png';
import mainMatchingReport_mobile from '../../../../public/img/main/mainMatchingReport_mobile.png';
import mainInputForm from '../../../../public/img/main/mainInputForm.png';
import mainInputForm_mobile from '../../../../public/img/main/mainInputForm_mobile.png';
import mainCompanyProject from '../../../../public/img/main/mainCompanyProject.png';
import mainCompanyProject_mobile from '../../../../public/img/main/mainCompanyProject_mobile.png';
import mainMyProject from '../../../../public/img/main/mainMyProject.png';
import mainMyProject_mobile from '../../../../public/img/main/mainMyProject_mobile.png';
import mainMentoring from '../../../../public/img/main/mainMentoring.png';
import mainMentoring_mobile from '../../../../public/img/main/mainMentoring_mobile.png';
import main_ItPeople from '../../../../public/img/main/main_ItPeople.svg';
import main_someoneWorking from '../../../../public/img/main/main_someoneWorking.svg';
import Image, { StaticImageData } from 'next/image';
import { SubTitle } from 'styles/mainStyle';
import { useMediaQuery } from 'react-responsive';

const Background = styled(Flex)`
    background: ${({ theme }) => theme.colors.white};
    height: 3661px;
    flex-direction: column;
    align-items: center;
    gap: 200px;
    padding: 200px 0;
    @media screen and (max-width: 1024px) {
        height: auto;
        gap: 100px;
        padding: 100px 20px;
    }
`;

const Chapter = styled.div`
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const ChapterContainer = styled(Flex)`
    flex-direction: column;
    gap: 20px;
    margin-top: 46px;
    @media screen and (max-width: 1024px) {
        margin-top: 50px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const ChapterBox = styled(Box)`
    border-radius: 20px;
    padding-top: 50px;
    padding-left: 50px;
    font-size: 28px;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
        width: 335px;
        height: 309px;
        font-size: 18px;
        padding: 30px 0 0 20px;
    }
`;

const ImageWrapper = styled(Box)`
    position: absolute;
`;

const ImageWrapperWithBack = styled(ImageWrapper)`
    border-radius: 10px 0px 0px 0px;
    width: 315px;
    height: 212px;
    padding: 8px 0 0 4px;
    background-color: ${({ theme }) => theme.colors.white};
`;

const CardBox = styled(Flex)`
    margin-top: 100px;
    gap: 80px;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 73px;
        gap: 19px;
    }
`;

const Chapter2Card = styled(Flex)`
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
    height: 643px;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    text-align: center;
    padding-bottom: 62px;
    @media screen and (max-width: 1024px) {
        width: 335px;
        height: 431px;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
        padding-bottom: 45px;
    }
`;

const CardDeco = styled(Box)`
    width: 500px;
    height: 30px;
    border-radius: 20px 20px 0px 0px;
    position: absolute;
    top: 0;
    left: 0;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 20px;
        border-radius: 10px 10px 0px 0px;
    }
`;

const CardTitle = styled.h4`
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-top: 72px;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        margin-top: 50px;
    }
`;

const CardDesc = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: #767676;
    font-weight: 700;
    > b {
        color: ${({ theme }) => theme.colors.blue};
    }
    @media screen and (max-width: 1024px) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const SmallText = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.mediumLarge};
    font-weight: 700;
`;

const RangeBar = styled(Flex)`
    margin-top: 10px;
`;

const RangeBarLeft = styled.div`
    width: 2.26px;
    height: 30px;
    background: linear-gradient(
        111deg,
        #317eff 4.02%,
        #8520f5 96.6%,
        #9536ff 96.6%
    );
`;

const RangeBarMiddle = styled.div`
    width: calc(618px - 5px);
    height: 3px;
    background: linear-gradient(
        111deg,
        #317eff 4.02%,
        #8520f5 96.6%,
        #9536ff 96.6%
    );
    margin-top: 13.5px;
`;

const ProcessItem = styled(Flex)`
    flex-direction: column;
    gap: 20px;
    align-items: center;
    @media screen and (max-width: 1024px) {
        gap: 15px;
    }
`;

const ProcessIconBack = styled(Flex)`
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    > span {
        font-size: 40px;
        color: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 1024px) {
        width: 40px;
        height: 40px;
        > span {
            font-size: 27px;
        }
    }
`;

const ProcessIconTitle = styled.div`
    font-size: ${({ theme }) => theme.fontSizes.large};
    font-weight: 700;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
`;

const HorizontalLine = styled.div`
    width: 60px;
    height: 3px;
    background: #e6e9ee;
    margin-top: 32px;
`;

const MainSection3 = () => {
    const onMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <Background>
            <Chapter>
                {!onMobile && <SubTitle>그래서 스타팅이 준비했어요.</SubTitle>}
                {onMobile && (
                    <SubTitle>
                        그래서,
                        <br /> 스타팅이 준비했어요.
                    </SubTitle>
                )}
                <ChapterContainer>
                    <ChapterBox
                        width={!onMobile && '1080px'}
                        height={!onMobile && '557px'}
                        backgroundColor={'#D9E8DA'}
                    >
                        기업과의 업무 적합성을
                        <br />볼 수 있는 매칭리포트
                        <ImageWrapper
                            top={{ base: '72px', lg: '97px' }}
                            left={{ base: '318px', lg: '20px' }}
                        >
                            <Image
                                src={
                                    !onMobile
                                        ? mainMatchingReport
                                        : mainMatchingReport_mobile
                                }
                                alt="mainMatchingReport"
                                width={!onMobile ? 944 : 315}
                            />
                        </ImageWrapper>
                    </ChapterBox>
                    {!onMobile && (
                        <Flex
                            gap={'20px'}
                            height={!onMobile && '404px'}
                            flexDirection={{ base: 'row', lg: 'column' }}
                        >
                            <ChapterBox
                                width={!onMobile && '413px'}
                                backgroundColor={'#E5ECF2'}
                            >
                                10분이면 끝나는 <br />
                                간편한 매칭 입력 폼
                                <ImageWrapper
                                    top={{ base: '151px', lg: '97px' }}
                                    left={{ base: '50px', lg: '20px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainInputForm
                                                : mainInputForm_mobile
                                        }
                                        alt="mainInputForm"
                                        width={!onMobile ? 368 : 315}
                                    />
                                </ImageWrapper>
                            </ChapterBox>
                            <ChapterBox
                                width={!onMobile && '647px'}
                                backgroundColor={'#D7DCF4'}
                            >
                                내 프로젝트 경험과 <br />
                                연관성 높은 기업 매칭
                                <ImageWrapper
                                    top={{ base: '151px', lg: '97px' }}
                                    left={{ base: '50px', lg: '20px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainCompanyProject
                                                : mainCompanyProject_mobile
                                        }
                                        alt="mainCompanyProject"
                                        width={!onMobile ? 440 : 301}
                                    />
                                </ImageWrapper>
                                <ImageWrapper
                                    top={{ base: '179px', lg: '152px' }}
                                    left={{ base: '233px', lg: '97px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainMyProject
                                                : mainMyProject_mobile
                                        }
                                        alt="mainMyProject"
                                        width={!onMobile ? 462 : 315}
                                    />
                                </ImageWrapper>
                            </ChapterBox>
                        </Flex>
                    )}
                    {onMobile && (
                        <>
                            <ChapterBox
                                width={!onMobile && '413px'}
                                backgroundColor={'#E5ECF2'}
                            >
                                10분이면 끝나는 <br />
                                간편한 매칭 입력 폼
                                <ImageWrapper
                                    top={{ base: '151px', lg: '97px' }}
                                    left={{ base: '50px', lg: '20px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainInputForm
                                                : mainInputForm_mobile
                                        }
                                        alt="mainInputForm"
                                        width={!onMobile ? 368 : 315}
                                    />
                                </ImageWrapper>
                            </ChapterBox>
                            <ChapterBox
                                width={!onMobile && '647px'}
                                backgroundColor={'#D7DCF4'}
                            >
                                내 프로젝트 경험과 <br />
                                연관성 높은 기업 매칭
                                <ImageWrapper
                                    top={{ base: '151px', lg: '97px' }}
                                    left={{ base: '50px', lg: '20px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainCompanyProject
                                                : mainCompanyProject_mobile
                                        }
                                        alt="mainCompanyProject"
                                        width={!onMobile ? 440 : 301}
                                    />
                                </ImageWrapper>
                                <ImageWrapper
                                    top={{ base: '179px', lg: '152px' }}
                                    left={{ base: '233px', lg: '97px' }}
                                >
                                    <Image
                                        src={
                                            !onMobile
                                                ? mainMyProject
                                                : mainMyProject_mobile
                                        }
                                        alt="mainMyProject"
                                        width={!onMobile ? 462 : 315}
                                    />
                                </ImageWrapper>
                            </ChapterBox>
                        </>
                    )}

                    <ChapterBox
                        width={!onMobile && '1080px'}
                        height={!onMobile && '557px'}
                        backgroundColor={'#F9E7FF'}
                    >
                        취업・이직 전 과정
                        <br />
                        1:1 멘토링
                        {!onMobile && (
                            <ImageWrapper
                                top={{ base: '73px', lg: '97px' }}
                                left={{ base: '317px', lg: '20px' }}
                            >
                                <Image
                                    src={
                                        !onMobile
                                            ? mainMentoring
                                            : mainMentoring_mobile
                                    }
                                    alt="mainMentoring"
                                    width={!onMobile ? 944 : 315}
                                />
                            </ImageWrapper>
                        )}
                        {onMobile && (
                            <ImageWrapperWithBack
                                top={{ base: '73px', lg: '97px' }}
                                left={{ base: '317px', lg: '20px' }}
                            >
                                <Image
                                    src={
                                        !onMobile
                                            ? mainMentoring
                                            : mainMentoring_mobile
                                    }
                                    alt="mainMentoring"
                                    width={!onMobile ? 944 : 315}
                                />
                            </ImageWrapperWithBack>
                        )}
                    </ChapterBox>
                </ChapterContainer>
            </Chapter>
            <Chapter>
                {!onMobile && (
                    <SubTitle>
                        아래 2가지만 충족되면 매칭받을 수 있어요
                    </SubTitle>
                )}
                {onMobile && (
                    <SubTitle>
                        아래 2가지만 충족되면
                        <br />
                        매칭받을 수 있어요
                    </SubTitle>
                )}
                <CardBox>
                    <Chapter2Card>
                        <CardDeco backgroundColor={'blue'} />
                        <div>
                            <CardTitle>
                                프로젝트 경험이
                                <br />
                                최소 1개 이상 있나요?
                            </CardTitle>
                            <Box marginTop={{ base: '48px', lg: '30px' }}>
                                <Image
                                    src={main_someoneWorking}
                                    alt="main_someoneWorking"
                                    width={onMobile && 278.414}
                                    height={onMobile && 176}
                                />
                            </Box>
                        </div>
                        <CardDesc>
                            <b>프로젝트 기반으로 매칭</b>이 되기 때문에
                            <br />
                            최소 1개 이상 프로젝트가 필요해요.
                        </CardDesc>
                    </Chapter2Card>
                    <Chapter2Card>
                        <CardDeco backgroundColor={'#8520F5'} />
                        <div>
                            <CardTitle>IT 직군 주니어 인가요?</CardTitle>
                            <Box marginTop={{ base: '71.86px', lg: '30px' }}>
                                <Image
                                    src={main_ItPeople}
                                    alt="main_ItPeople"
                                    width={onMobile && 219}
                                    height={onMobile && 219}
                                />
                            </Box>
                        </div>
                        <CardDesc>
                            <b style={{ color: '#8520F5' }}>
                                기획자, 개발자, 디자이너
                            </b>
                            만
                            <br />
                            매칭을 지원하고 있어요.
                        </CardDesc>
                    </Chapter2Card>
                </CardBox>
            </Chapter>
            <Chapter>
                {!onMobile && (
                    <SubTitle>무료로 진행되는 매칭 프로세스</SubTitle>
                )}
                {onMobile && (
                    <SubTitle>
                        무료로 진행되는
                        <br />
                        매칭 프로세스
                    </SubTitle>
                )}
                {!onMobile && (
                    <Box marginTop={'100px'} width={'1080px'}>
                        <Box paddingLeft={'321px'}>
                            <SmallText>Day 5</SmallText>
                        </Box>
                        <RangeBar>
                            <RangeBarLeft />
                            <RangeBarMiddle />
                            <RangeBarLeft />
                        </RangeBar>
                        <Flex marginTop={'33px'} gap={'37px'}>
                            <ProcessItem>
                                <ProcessIconBack backgroundColor={'blue'}>
                                    <span className="material-symbols-outlined">
                                        assignment
                                    </span>
                                </ProcessIconBack>
                                <ProcessIconTitle>매칭 신청</ProcessIconTitle>
                            </ProcessItem>
                            <HorizontalLine />
                            <ProcessItem>
                                <ProcessIconBack backgroundColor={'#2F6AFD'}>
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                </ProcessIconBack>
                                <ProcessIconTitle>기업 소싱</ProcessIconTitle>
                            </ProcessItem>
                            <HorizontalLine />
                            <ProcessItem>
                                <ProcessIconBack backgroundColor={'#4756FB'}>
                                    <span className="material-symbols-outlined">
                                        assessment
                                    </span>
                                </ProcessIconBack>
                                <ProcessIconTitle>
                                    매칭 리포트 발행
                                </ProcessIconTitle>
                            </ProcessItem>
                            <HorizontalLine />
                            <ProcessItem>
                                <ProcessIconBack backgroundColor={'#514DFA'}>
                                    <span className="material-symbols-outlined">
                                        drive_file_rename_outline
                                    </span>
                                </ProcessIconBack>
                                <ProcessIconTitle>면접 전형</ProcessIconTitle>
                            </ProcessItem>
                            <HorizontalLine />
                            <ProcessItem>
                                <ProcessIconBack backgroundColor={'#8520F5'}>
                                    <span className="material-symbols-outlined">
                                        verified
                                    </span>
                                </ProcessIconBack>
                                <ProcessIconTitle>취업 성공</ProcessIconTitle>
                            </ProcessItem>
                        </Flex>
                    </Box>
                )}
                {onMobile && (
                    <Flex
                        flexDirection={'column'}
                        marginTop={'50px'}
                        gap={'30px'}
                    >
                        <ProcessItem>
                            <ProcessIconBack backgroundColor={'blue'}>
                                <span className="material-symbols-outlined">
                                    assignment
                                </span>
                            </ProcessIconBack>
                            <ProcessIconTitle>매칭 신청</ProcessIconTitle>
                        </ProcessItem>
                        <ProcessItem>
                            <ProcessIconBack backgroundColor={'#2F6AFD'}>
                                <span className="material-symbols-outlined">
                                    search
                                </span>
                            </ProcessIconBack>
                            <ProcessIconTitle>기업 소싱</ProcessIconTitle>
                        </ProcessItem>

                        <ProcessItem>
                            <ProcessIconBack backgroundColor={'#4756FB'}>
                                <span className="material-symbols-outlined">
                                    assessment
                                </span>
                            </ProcessIconBack>
                            <ProcessIconTitle>
                                매칭 리포트 발행
                            </ProcessIconTitle>
                        </ProcessItem>

                        <ProcessItem>
                            <ProcessIconBack backgroundColor={'#514DFA'}>
                                <span className="material-symbols-outlined">
                                    drive_file_rename_outline
                                </span>
                            </ProcessIconBack>
                            <ProcessIconTitle>면접 전형</ProcessIconTitle>
                        </ProcessItem>

                        <ProcessItem>
                            <ProcessIconBack backgroundColor={'#8520F5'}>
                                <span className="material-symbols-outlined">
                                    verified
                                </span>
                            </ProcessIconBack>
                            <ProcessIconTitle>취업 성공</ProcessIconTitle>
                        </ProcessItem>
                    </Flex>
                )}
            </Chapter>
        </Background>
    );
};

export default MainSection3;
