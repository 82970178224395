import Footer from 'components/Footer';
import MainGnb from 'components/gnb/MainGnb';
import Flex from 'components/layout/Flex';
import MainSection1 from 'components/templates/main/MainSection1';
import MainSection2 from 'components/templates/main/MainSection2';
import MainSection3 from 'components/templates/main/MainSection3';
import MainSection4 from 'components/templates/main/MainSection4';
import { GetServerSidePropsContext, NextPage } from 'next';
import Head from 'next/head';
import React, { useEffect, memo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import ChannelService from 'utils/ChannelService';

const Main: NextPage = () => {
    const onMobile = useMediaQuery({ query: '(max-width: 640px)' });

    useEffect(() => {
        window.scrollTo(0, 0);
        //
        const CT = new ChannelService();
        !onMobile &&
            CT.boot({
                pluginKey: process.env.NEXT_PUBLIC_CHANNEL_API,
            });

        return () => {
            !onMobile && CT.shutdown();
        };
    }, []);

    return (
        <>
            <Head>
                {/* 브라우저에서 보이는 */}
                <title>스타팅 - 나랑 딱 맞는 기업에서 커리어 시작하기</title>
            </Head>
            <main>
                <Flex flexDirection={'column'}>
                    <MainGnb />
                    <MainSection1 />
                    <MainSection2 />
                    <MainSection3 />
                    <MainSection4 />
                </Flex>
            </main>
            <Footer type="white" />
        </>
    );
};

export default memo(Main);
