import React, { useState } from 'react';
import { Container, Box, TabBox, Tab, LoginButton } from './PCGnb';
import Image from 'next/image';
import { mobileLogo, pcLogo } from '../../../public/img/s3Images';
import Link from 'next/link';
import Flex from 'components/layout/Flex';
import {
    AllContainer,
    CloseIcon,
    Logo,
    LogoDiv,
    LogoLink,
    MobileBox,
    MobileContainer,
} from './MobileGnb';
import { useRouter } from 'next/router';
import {
    DropdownBackground,
    DropdownItem,
    DropdownList,
    TextLinkBox,
} from 'styles/header/headerStyle';
import { FlexDiv, MobileBlock, PCBlock } from 'styles/commonStyle';
import styled from 'styled-components';

const ButtonLinkBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const MobileLongButton = styled(FlexDiv)`
    width: 100%;
    background: linear-gradient(
        95deg,
        rgba(26, 124, 255, 0.8) 0%,
        rgba(133, 32, 245, 0.8) 93.68%
    );
    border-radius: 10px;
    height: 39px;
    color: white;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
`;

const MobileLongButtonGray = styled(MobileLongButton)`
    color: #b4b4b4;
    background: rgba(242, 242, 242, 0.1);
    border: 1px solid #f2f2f2;
    box-shadow: none;
`;

const MainGnb = () => {
    const router = useRouter();

    const [dropDown, setDropDown] = useState(false);

    return (
        <>
            <PCBlock>
                <Container backgroundColor="white">
                    <Box>
                        <div>
                            <a href="/">
                                <Image
                                    src={pcLogo}
                                    width={104}
                                    height={24}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <Flex
                            alignItems={'center'}
                            gap="43px"
                            marginRight={'22px'}
                        >
                            <TabBox color="#4b4d4f">
                                <Link href={'/resumes'} target="_blank">
                                    <div>
                                        <Tab>이력서</Tab>
                                    </div>
                                </Link>
                                <Link href={'/growthon'} target="_blank">
                                    <div>
                                        <Tab>그로스톤</Tab>
                                    </div>
                                </Link>
                            </TabBox>
                            <a
                                href="https://match.start-ing.kr/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LoginButton>기업 서비스</LoginButton>
                            </a>
                        </Flex>
                    </Box>
                </Container>
            </PCBlock>
            <MobileBlock>
                <AllContainer color="#4b4d4f">
                    {!dropDown && (
                        <MobileContainer backgroundColor="white">
                            <MobileBox>
                                <LogoDiv onClick={() => router.push('/')}>
                                    <img
                                        src={mobileLogo}
                                        width={65}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        alt="logo"
                                    />
                                </LogoDiv>
                                <span
                                    className="material-symbols-outlined"
                                    onClick={() => setDropDown(true)}
                                >
                                    menu
                                </span>
                            </MobileBox>
                        </MobileContainer>
                    )}
                    {dropDown && (
                        <DropdownBackground>
                            <MobileContainer
                                backgroundColor="#fff"
                                dropDownType="white"
                            >
                                <MobileBox>
                                    <LogoLink href="/">
                                        <Logo
                                            src={mobileLogo}
                                            alt="logo"
                                            width={65}
                                        />
                                    </LogoLink>

                                    <CloseIcon
                                        className="sc-jrcTuL jbHJAo material-symbols-outlined"
                                        onClick={() => setDropDown(false)}
                                    >
                                        close
                                    </CloseIcon>
                                </MobileBox>
                            </MobileContainer>
                            <DropdownList dropDownType="white">
                                <TextLinkBox>
                                    <Link href={'/resumes'} target="_blank">
                                        <DropdownItem>이력서</DropdownItem>
                                    </Link>
                                    <Link href={'/growthon'} target="_blank">
                                        <DropdownItem>그로스톤</DropdownItem>
                                    </Link>
                                </TextLinkBox>
                                <ButtonLinkBox>
                                    <a
                                        href="https://form.start-ing.kr/match-person"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MobileLongButton>
                                            매칭 신청하기
                                        </MobileLongButton>
                                    </a>
                                    <a
                                        href="https://match.start-ing.kr/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <MobileLongButtonGray>
                                            기업 서비스
                                        </MobileLongButtonGray>
                                    </a>
                                </ButtonLinkBox>
                            </DropdownList>
                        </DropdownBackground>
                    )}
                </AllContainer>
            </MobileBlock>
        </>
    );
};

export default MainGnb;
