import Flex from 'components/layout/Flex';
import FeedbackItem from 'components/organisms/FeedbackItem';
import React from 'react';
import styled from 'styled-components';
import { FlexDiv } from 'styles/commonStyle';
import { SubTitle } from 'styles/mainStyle';
import profileImage1 from '../../../../public/img/main/profileImage1.png';
import profileImage2 from '../../../../public/img/main/profileImage2.png';
import profileImage3 from '../../../../public/img/main/profileImage3.png';
import { useMediaQuery } from 'react-responsive';

const Background = styled(Flex)`
    background: linear-gradient(
        90deg,
        rgba(26, 124, 255, 0.1) -0.99%,
        rgba(133, 32, 245, 0.1) 100%
    );
    height: 767px;
    flex-direction: column;
    gap: 100px;
    padding: 100px 0 0;
    @media screen and (max-width: 1024px) {
        height: calc(991px + 279px);
        gap: 50px;
        padding: 100px 0 70px;
        align-items: center;
    }
`;

export const Carousel = styled.div`
    display: flex;
    animation: marquee 20s linear infinite;

    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;

        &.second {
            animation: marqueeSecond 20s linear infinite;
        }
    }

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes marqueeSecond {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;

const CustomCarousel = styled(Carousel)`
    height: 500px;
`;

export const CarouselContainer = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;

    :hover div {
        animation-play-state: paused;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const renderFeedbackItems = () => (
    <>
        <FeedbackItem
            tagText="기업 매칭"
            imageSrc={profileImage2}
            tagBackgroundColor="#1a7cff"
            name="박**"
            job="Project Manager"
            content="기업 채용 포지션 업무 내용과 구직자의 경험을 펼쳐놓고 적합, 부적합을 확인 한 뒤 상호 간 업무에 대한 구체적인 논의가 가능하여 만족스러웠습니다.
                        <br/>그리고 <b>업무 적합성이 높은 기업을 매칭 받아서 좋았어요.<b/>"
            perfectScore
        />
        <FeedbackItem
            tagText="1:1 멘토링"
            imageSrc={profileImage3}
            tagBackgroundColor="#8520F5"
            name="권**"
            job="B2B Marketer"
            content="이력서가 <b>중구난방하고 한눈에 들어오지 않는 문제</b>가 있었는데, 멘토링을 받고난 후 궁금하고 의문이었던 부분들이 정말 <b>전부 해소</b>되었고 <b>확실히 다르다는 생각</b>을 했어요.
            <br/>이후 ‘이런 기업 서류합격을 어떻게’ 싶은 곳에서 면접 제안도 왔었어요."
            perfectScore
        />
        <FeedbackItem
            tagText="기업 매칭"
            imageSrc={profileImage1}
            tagBackgroundColor="#1a7cff"
            name="이**"
            job="UI·UX Designer"
            content="나의 업무 스킬과 능력을 고려하여 <b>딱 맞는 기업을 선별적으로 매칭</b>시켜 주셔서 정말로 만족스러웠습니다."
            perfectScore={false}
        />
    </>
);

const MainSection4 = () => {
    const onMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    return (
        <Background>
            <SubTitle>고객 이용 후기</SubTitle>
            {!onMobile && (
                <CarouselContainer>
                    <CustomCarousel>{renderFeedbackItems()}</CustomCarousel>
                    <CustomCarousel>{renderFeedbackItems()}</CustomCarousel>
                </CarouselContainer>
            )}
            {onMobile && (
                <Flex flexDirection={'column'} gap={'20px'}>
                    {renderFeedbackItems()}
                </Flex>
            )}
        </Background>
    );
};

export default MainSection4;
