import React from 'react';
import styled from 'styled-components';
import { MobileBlock, PCBlock } from 'styles/commonStyle';

const StyledFooterContainer = styled.div<{
    type?: string;
}>`
    width: 100%;
    height: 277px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
        props.type === 'white'
            ? 'background-color: #fff;'
            : 'background-color: #101016;'}

    @media screen and (max-width: 1024px) {
        padding: 36px 8% 30px 8%;
        align-items: flex-start;
        flex-direction: column;
    }
`;

const ContentBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 1216px;
    @media screen and (max-width: 1220px) {
        width: 1000px;
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StyledListWrapper = styled.ul`
    display: flex;
    gap: 51.001px;

    @media screen and (max-width: 1024px) {
        gap: 1.3125rem;
    }
`;

const StyledLink = styled.a`
    font-size: 15.999px;
    ${props => (props.type === 'white' ? 'color: #4B4D4F;' : 'color: #b4b4b4;')}
    font-weight: 700;

    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }

    &:hover {
        ${props => (props.type === 'white' ? 'color: #000;' : 'color: #fff;')}
    }
`;

const StyledCompanyInfoWrapper = styled.div`
    margin-top: 39.999px;

    @media screen and (max-width: 1024px) {
        margin-top: 1.875rem;
        font-size: 1.875rem;
    }
`;

const StyledCompanyInfo = styled.div<{
    type?: string;
}>`
    font-size: 14.001px;
    font-weight: 400;
    line-height: 24px;
    ${props => (props.type === 'white' ? 'color: #4B4D4F;' : 'color: #b4b4b4;')}

    @media screen and (max-width: 1024px) {
        font-size: 12px;
        line-height: 24px;
    }
    @media screen and (max-width: 374px) {
        font-size: 10px;
    }
`;

/**
 * type 프롭스는 white 또는 black을 받습니다.
 */

export default function Footer({ type = 'white' }) {
    return (
        <footer>
            <StyledFooterContainer type={type}>
                <ContentBox>
                    <div>
                        <StyledListWrapper>
                            <StyledLink
                                type={type}
                                href="https://www.momit.co.kr"
                                target="_blank"
                            >
                                회사소개
                            </StyledLink>
                            <StyledLink
                                type={type}
                                href="https://momit.notion.site/VER-2-0-d6ade6eb0b944ca291d76ab456219231?pvs=4"
                                target="_blank"
                            >
                                이용약관
                            </StyledLink>
                            <StyledLink
                                type={type}
                                href="https://momit.notion.site/VER-1-1-805dc04d6bfe496e9d95646e0a1b2e67?pvs=4"
                                target="_blank"
                            >
                                개인정보처리방침
                            </StyledLink>
                        </StyledListWrapper>

                        <PCBlock>
                            <StyledCompanyInfoWrapper>
                                <StyledCompanyInfo type={type}>
                                    주식회사 모밋 | 대표이사 : 김홍찬 | 사업자
                                    등록번호 : 313-88-02066
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    통신판매번호 : 2022-서울중구-0936 |
                                    직업정보제공번호 : 서울청 제 2023-2 호 |
                                    유료직업소개번호 : 제
                                    2023-3010202-14-5-00012 호
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    대표번호 : 1688-7360 | support@start-ing.kr
                                    | 주소 : 서울특별시 중구 을지로 50
                                    스파크플러스 17층
                                </StyledCompanyInfo>
                            </StyledCompanyInfoWrapper>
                        </PCBlock>

                        <MobileBlock>
                            <StyledCompanyInfoWrapper>
                                <StyledCompanyInfo type={type}>
                                    주식회사 모밋 | 대표이사 : 김홍찬 |
                                    사업자번호 : 313-88-02066
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    통신판매번호 : 2022-서울중구-0936
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    직업정보제공번호 : 서울청 제 2023-2 호
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    유료직업소개번호 : 제
                                    2023-3010202-14-5-00012 호
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    대표번호 : 1688-7360 | support@start-ing.kr
                                </StyledCompanyInfo>
                                <StyledCompanyInfo type={type}>
                                    주소 : 서울특별시 중구 을지로 50
                                    스파크플러스 17층
                                </StyledCompanyInfo>
                            </StyledCompanyInfoWrapper>
                        </MobileBlock>
                    </div>

                    <StyledCompanyInfo type={type}>
                        <b style={{ fontWeight: '500' }}>
                            © {new Date().getFullYear()} MOMIT Inc. All Rights
                            Reserved.{' '}
                        </b>
                    </StyledCompanyInfo>
                </ContentBox>
            </StyledFooterContainer>
        </footer>
    );
}
